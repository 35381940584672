@import '../../styles/variables.scss';
.form {
    background: #fff;
    border: var(--form-border);
    border-radius: 20px;
    max-width: 1200px;
    // margin-bottom: 50px;
    height: auto;
    padding: var(--form-padding);
    z-index: 9999;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;
        .title {
            color: var(--main-color);
            text-transform: uppercase;
            font-size: 24px;
        }
        .start {
            font-size: 20px;
            font-weight: 400;
        }
    }

    &-description {
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
    }
    &-tariffs {
        display: flex;
        align-items: center;
        width: auto;
        overflow: hidden;
        overflow-x: auto;
        &-block {
            cursor: pointer;
            border: 1px solid #d9d9d9;
            border-radius: 12px;
            color: #777777;
            min-width: 320px;
            height: 80px;
            padding: 0px 10px 0 30px;
            margin-right: 38px;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            position: relative;
            .tabIcon {
                border: 1px solid #000;
            }
            div {
                width: 220px;
                z-index: 9999;
            }
            img {
                position: absolute;
                right: 10px;
                z-index: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .active {
            color: #000;
            border: 1px solid var(--main-color);
        }
    }
    &-body {
        &-title {
            font-size: 36px;
            line-height: 36px;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 20px;
        }
        &-container {
            border-top: 1px solid #d9d9d9;
            padding-top: 20px;
            .form-body-content-container {
                height: auto;
                max-height: 460px;
                overflow-y: auto;
            }
            .form-body-content {
                font-size: 14px;
                font-weight: 300;
                line-height: 22.4px;
            }
        }
    }
    &-footer {
        // border: 1px solid #000;
        .ticker {
            height: 40px;
            padding: 0;
            border-width: 0px 2px;
            border-style: solid;
            border-color: #ededed;
            font-size: 24px;
            font-weight: 300;
            text-transform: uppercase;
            line-height: 40px;
        }
        .terms {
            // border: 1px solid red;
            font-size: 10px;
            a {
                color: #000;
            }
        }
    }

    @media (max-width: 992px) {
        max-height: auto;
        &-body {
            &-container {
                border-top: 1px solid #d9d9d9;
                padding-top: 20px;
            }
        }
        &-footer {
            display: flex;
            flex-direction: column-reverse;
            .terms {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 35px 20px 10px;
        &-header {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 26px;
            .start {
                font-size: 14px;
                margin-bottom: 34px;
            }
        }
        .form-tariffs {
            &-block {
                min-width: 208px !important;
                height: 60px;
                padding: 0px 10px 0 20px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                img {
                    width: 60px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}
::-webkit-scrollbar-track {
    background-color: #d9d9d9;
}
::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 15px;
}
@media (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 2px;
    }
}
