@import '../../../styles/variables.scss';
.form-mini {
    background: var(--bgColor-color);
    border-radius: 20px;
    max-width: 1200px;
    height: auto;
    // margin-bottom: 50px;
    border: var(--form-border);
    padding: var(--form-padding);
    z-index: 9999;
    display: flex;
    align-items: center;
    overflow: hidden;

    &-container {
        width: 100%;
        min-height: 300px;
        align-items: flex-start;
        position: relative;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .quote {
                position: absolute;
                bottom: 15px;
                width: 58.3333%;
            }
            .title {
                font-weight: 600;
                color: #000;
                font-size: 36px;
                margin-bottom: 20px;
                line-height: 36px;
            }
            .body {
                border-top: 1px solid #d9d9d9;
                // border: 1px solid #000;
                max-height: 300px;
                height: auto;
                // min-height: 200px;
                padding-top: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .start {
                    font-size: 20px;
                }
                &-description {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 15px;
                }
                &-container {
                    height: 100%;
                    font-size: 14px;
                    line-height: 24px;
                    overflow-y: auto;
                }
                .ticker {
                    margin-top: 20px;
                    height: 40px;
                    padding: 0;
                    border-width: 0px 2px;
                    border-style: solid;
                    border-color: #ededed;
                    font-size: 24px;
                    font-weight: 300;
                    text-transform: uppercase;
                    line-height: 40px;
                }
            }
        }
        .sendForm {
            .terms {
                margin-top: 10px;
                font-size: 10px;
                a {
                    color: #000;
                }
            }
        }
    }
    @media (max-width: 991px) {
        justify-content: center;
        &-container {
            .content {
                height: auto;
                min-height: 100px;
                max-height: 100%;
                position: relative;
                .quote {
                    position: static;
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 768px) {
        &-container {
            .content {
                height: auto;
                .quote {
                    position: static;
                }
                .title {
                    font-size: 24px;
                }
            }
        }
    }
}
