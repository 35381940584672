@import '../../styles/variables.scss';
.quote {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    &-body {
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        margin-bottom: 40px;
        span {
            font-weight: 600;
        }
    }
    &-footer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .img-container {
            margin-right: 30px;
            img {
                width: 98px;
            }
        }
        .author {
            font-size: 16px;
            font-weight: 300;
            line-height: 19px;
            color: var(--main-color);
            span {
                font-weight: 600;
            }
        }
    }
}
