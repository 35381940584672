@import '../../styles/variables.scss';

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14px;
    width: 100%;
    opacity: 0.5;
    img {
        margin-right: 14px;
    }
    div {
        width: 300px;
        h3 {
            font-size: 14px;
            font-weight: 300;
            color: #000;
            line-height: 20px;
            span {
                font-weight: 600;
            }
        }
    }
    @media (max-width: 993px) {
        font-weight: 300 !important;
        width: 100%;
        padding-top: 30px;
        justify-content: center;
        margin-bottom: 33px;
        div {
            max-width: 300px;
            width: auto;
            h1 {
                opacity: 0.5;
                font-size: 14px;
                font-weight: 300 !important;
                color: #000 !important;
                line-height: 20px;
                span {
                    font-weight: 700;
                }
            }
        }
    }
}
