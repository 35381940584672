@import '../../styles/variables.scss';
// height: calc(100% - 20px)

.info {
    height: auto;
    box-sizing: border-box;
    margin-bottom: 10px;
    .price-block {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .price {
            color: var(--main-color);
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 10px;
            font-family: 'Gilroy', sans-serif;
            margin-right: 10px;
        }
    }

    .oldPrice {
        text-decoration: line-through;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
    }
    .discountProduct {
        opacity: 0.7;
        background: #ededed;
        border-radius: 6px;
        width: 100%;
        padding: 7px 10px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        max-height: 42px;
        overflow-y: auto;
    }
}
.booking-form {
    &-title {
        font-weight: 300;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .field {
        height: 50px;
    }
    input {
        font-family: 'Gilroy', sans-serif;
        font-size: 14px;
        font-weight: 300;
        border-radius: 6px;
        border: 1px solid;
        border-color: var(--borderColor-color);
        padding: 13px 25px;
        background: #ededed;
        &:focus {
            border: none;
            box-shadow: 0px 0px 5px var(--main-color) !important;
        }
    }
    .phone {
        position: relative;
        select.PhoneInputCountrySelect {
            display: none;
        }
        .PhoneInputCountryIcon {
            width: 20px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        input.PhoneInputInput {
            font-family: 'Gilroy', sans-serif;
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            &:focus {
                border-color: transparent;
                outline: none;
                box-shadow: 0px 0px 5px var(--main-color) !important;
            }
        }
    }
    button.p-button {
        width: 100%;
        font-weight: 600;
        border: none;
        background: var(--main-color);
        margin-bottom: 10px;
        cursor: pointer;
        box-shadow: none;
        &:hover {
            background: var(--main-color);
            box-shadow: 0px 0px 10px var(--main-color);
        }
    }
}

@media (max-width: 991px) {
    .info {
        height: auto;
    }
    .booking-form {
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .field {
            width: 100%;
        }
        button.p-button {
            width: 100%;
        }
    }
}
