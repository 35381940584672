@import './styles/variables.scss';
@font-face {
    font-family: Gilroy;
    src: url('./fonts/Gilroy/gilroy-regular.ttf');
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Gilroy', sans-serif;
    background: var(--bgColor-color);
    /* padding: 20px; */
    overflow: hidden;}
.App {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error-404 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    color: var(--main-color);
}
@media (max-height: 992px) {
    body {
        overflow-y: auto;
        /* padding: 20px; */
    }
}
@media (max-width: 992px) {
    body {
        overflow-y: auto;
    }
    .App {
        justify-content: flex-start;
        align-items: center;
        height: auto;
    }
}
@media (max-width: 428px) {
    body {
        background-image: none;
    }
}
