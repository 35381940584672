@import '../../styles/variables.scss';

.thankyou-letter {
    .title {
        color: var(--main-color);
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .letter {
        font-size: 14px;
        span {
            color: var(--main-color);
        }
    }
}
